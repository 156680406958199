<template>
  <div>
    <div class="category-box newCategory">
      <div class="play_games">
        <div class="catgory_head">
          <div class="title">
            <h4>
              <img src="/static/images/earning_coins_ic.svg" class="titile_icon coinAnimate" />
              {{$t('Playandwin')}}
            </h4>
          </div>
          <!-- <div class="categorytab">
            <img src="/static/images/right_arrow_ic.svg" />
          </div>-->
        </div>

        <!-- :style="getPanelColor(game.bgColor)" <h4>{{ $t('Playandwin') }}</h4> background: linear-gradient(90deg, rgb(186, 11, 0), rgb(186, 11, 0), rgb(186, 11, 0), transparent); -->
        <div class="games_grid_innner">
          <ul>
            <li v-for="game in shuffleGames" :key="game.gameReferenceId">
              <div class="game-imgbox" @click="openGameLevel(game.gameReferenceId)">
                <div class="game_smallbox">
                  <img  class="lozad game_image" src="../assets/images/gray_image.jpg" :data-src="game.smallIconImageUrl" />
                  <div class="loader-spin-game" v-if="spinnerLoading && isActive === game.gameReferenceId">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
                </div>

                <div class="title-block">
                  <h2 class="game-title">{{ game.name }}</h2>

                  <div class="gameStatus">
                    <span class="palyer-counter">
                      {{
                      `${playerCount[game.gameReferenceId]} Players`
                      }}
                    </span>
                    <!-- <span class="textLabel">{{gameLabel}}</span> -->
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { lozadObserver } from "../main";
export default {
  name: "NewGameCategory",
  props: {
    games: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      spinnerLoading: false,
      gameLabel: "new"
    };
  },

  computed: {
    shuffleGames: function(){
      let _this = this;
      // making new array from this.games Object
      let games = Object.keys(this.games).map(function (key) {
        return _this.games[key];
      });
      // picking game from an array of games and placing it sequentially at last index
      for (let i = games.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [games[i], games[j]] = [games[j], games[i]];
      }
      this.updateObserver();
      return games
    },

    playerCount: function() {
      let counts = Object.values(this.games).map(game => {
        let playerCount = Object.values(game.contests || {}).reduce(
          (prev, next) => {
            return next.currentPlayers ? prev + next.currentPlayers : prev;
          },
          0
        );
        return {
          [game.gameReferenceId]: playerCount || 0
        };
      });
      return Object.assign({}, ...counts);
    }
  },

  methods: {
    updateObserver(){
          this.$nextTick().then(()=>{
            lozadObserver.observe();
          })
    },
    getPanelColor(data) {
      return {
        background: `linear-gradient(90deg, ${data}, ${data}, ${data}, transparent)`
      };
    },

    openGameLevel(refId) {
      this.lastRefToListen = refId;
      this.spinnerLoading = true;
      this.isActive = refId;
      const url = `/${refId}`;
      setTimeout(() => {
        this.spinnerLoading = false;
        this.$router.push(url);
      }, 1000);
    }
  }
};
</script>
<style>
</style>
